import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NavController} from '@ionic/angular';
import {AuthService} from './auth.service';

@Injectable()
export class LoginActivate {
    constructor(private authService: AuthService,
                private navCtrl: NavController) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this.authService.loggedIn().then((isLoggedIn) => {
            if (!isLoggedIn) {
                this.navCtrl.navigateRoot(['/connexion']);
                return false;
            } else {
                return true;
            }
        });
    }
}
