import {Injectable} from '@angular/core';
import {Preferences as Storage} from '@capacitor/preferences';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiTokenService {
  private token;

  constructor() {
    this.token = '';
  }

  async resetToken() {
    this.token = '';
  }

  public async getHeader(){
    this.token = await Storage.get({key: 'id_token_filrouge2new'});
    return new HttpHeaders({ Authorization: 'Bearer ' + this.token.value});
  }
}
