import { AnimationController, Animation, createAnimation } from '@ionic/angular';
 
export const enterAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
    const backdropAnimation = createAnimation()
      .addElement(baseEl.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(baseEl.querySelector('.modal-wrapper')!)
     /* .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' }
      ]);*/
      .beforeStyles({ opacity: 1 })
      .fromTo('transform', 'translateY(-1000px)', 'translateY(0)');
      

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

export const leaveAnimation = (baseEl: any) => {
    return enterAnimation(baseEl).direction('reverse');
  };


