import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { PipesModule } from "./pipes/pipes.module";
import { ModalPicturesComponent } from "./components/modal-pictures/modal-pictures.component";
import { ModalSynchroComponent } from "./components/modal-synchro/modal-synchro.component";
import { enterAnimation } from "./animations/nav-animations";
import { LoginActivate } from "./services/login-activate.service";
import { LaunchNavigator } from "@awesome-cordova-plugins/launch-navigator/ngx";
import { ModalScanComponent } from "./components/modal-scan/modal-scan.component";
import { SwiperModule } from "swiper/angular";
import { KeepAwakeWeb } from "@capacitor-community/keep-awake/dist/esm/web";
import { ModalSosComponent } from "./components/modal-sos/modal-sos.component";
import { ComponentsModule } from "./components/components.module";

@NgModule({
  declarations: [
    AppComponent,
    ModalPicturesComponent,
    ModalSynchroComponent,
    ModalScanComponent,
    ModalSosComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: "ios",
      navAnimation: enterAnimation,
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    PipesModule,
    SwiperModule,
    ComponentsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LoginActivate,
    LaunchNavigator,
    KeepAwakeWeb,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
