import {Injectable} from '@angular/core';
import {Geolocation} from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class GeoServiceService {
  
  posLat;
  posLng;

  constructor() {
    Geolocation.getCurrentPosition().then((coordinates) => {
      this.posLat = coordinates.coords.latitude;
      this.posLng = coordinates.coords.longitude;
    }).catch((err) => {
      console.log('erreur geoloc', err);
      this.posLat = null;
      this.posLng = null;
    });
  }

  // convertit degrés en radian
  deg2rad(x) {
    return Math.PI * x / 180;
  }

  // calcule la distance en mètres entre deux points à partir de leurs coordonnées gps
  get_distance_m(lat1, lng1, lat2, lng2) {
    const earthRadius = 6378137;   // Terre = sphère de 6378km de rayon
    const rlo1 = this.deg2rad(lng1);    // CONVERSION
    const rla1 = this.deg2rad(lat1);
    const rlo2 = this.deg2rad(lng2);
    const rla2 = this.deg2rad(lat2);
    const dlo = (rlo2 - rlo1) / 2;
    const dla = (rla2 - rla1) / 2;
    const a = (Math.sin(dla) * Math.sin(dla)) + Math.cos(rla1) * Math.cos(rla2) * (Math.sin(dlo) * Math.sin(dlo));
    const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (earthRadius * d);
  }
}
