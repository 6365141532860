import { Injectable } from "@angular/core";
import { ActionSheetController, Platform } from "@ionic/angular";
import { Capacitor } from "@capacitor/core";
import {
  Camera,
  CameraResultType,
  CameraSource,
  GalleryPhoto,
} from "@capacitor/camera";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Photo } from "../interfaces/photo";

@Injectable({
  providedIn: "root",
})
export class PhotoService {
  public photos: Photo[] = [];
  private platform: Platform;

  constructor(
    platform: Platform,
    private actionSheetController: ActionSheetController,
  ) {
    this.platform = platform;
  }

  public async addPhoto(idMission, type, campagnenew = false) {
    let galleryPhotos: GalleryPhoto[] = [];
    let selectedPhotos: Photo[] = [];

    const actionSheet = await this.actionSheetController.create({
      header: "Ajouter des photos",
      buttons: [
        {
          text: "Sélectionner plusieurs photos",
          handler: async () => {
            const gallery = await Camera.pickImages({
              quality: 75,
              presentationStyle: "popover",
              correctOrientation: true,
              height: 1024,
              width: 1024,
              limit: 10,
            });
            galleryPhotos = gallery.photos;
          },
        },
        {
          text: "Prendre une photo",
          handler: async () => {
            let photo = await Camera.getPhoto({
              quality: 75,
              resultType: CameraResultType.Uri,
              saveToGallery: true,
              source: CameraSource.Camera,
              presentationStyle: "fullscreen",
              height: 1024,
              width: 1024,
            });
            galleryPhotos.push({
              path: photo.path,
              format: "jpeg",
              webPath: photo.webPath,
            });
          },
        },
        {
          text: "Annuler",
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();

    await actionSheet.onDidDismiss().then(async () => {
      for await (const photo of galleryPhotos) {
        const savedImageFile = await this.savePicture(photo, campagnenew);
        const p: Photo = {
          idMission,
          type,
          filepath: savedImageFile.filepath,
          webviewPath: savedImageFile.webviewPath,
          isThumbnail: false,
          webPath: photo.webPath,
        };
        this.photos.unshift(p);
        selectedPhotos.push(p);
      }
    });

    return selectedPhotos;
  }

  public async loadSaved(photo) {
    if (!this.platform.is("hybrid")) {
      // Read each saved photo's data from the Filesystem
      try {
        const readFile = await Filesystem.readFile({
          path: photo.filepath,
          directory: Directory.Data,
        });
        photo.webviewPath = `data:image/jpeg;base64,${readFile.data}`;
      } catch (e) {
        console.log("erreur readFile", e);
      }
    }
    return photo;
  }

  async savePictureFromApi(id, pictureNative, base64 = null) {
    let base64Data = base64;
    if (!base64) {
      base64Data = (await this.convertBlobToBase64(pictureNative)) as string;
    }

    const fileName = id + ".jpeg";
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });

    if (this.platform.is("hybrid")) {
      return {
        id,
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    } else {
      return {
        id,
        filepath: fileName,
        webviewPath: pictureNative.webPath,
      };
    }
  }

  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  private async savePicture(galleryPhoto: GalleryPhoto, campagnenew = false) {
    // Convert photo to base64 format, required by Filesystem API to save
    let base64Data = await this.readAsBase64(galleryPhoto);

    // Write the file to the data directory
    const fileName = new Date().getTime() + ".jpeg";
    await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });

    return {
      filepath: fileName,
      webviewPath: galleryPhoto.webPath,
    };
  }

  private async readAsBase64(galleryPhoto: GalleryPhoto) {
    // "hybrid" will detect Cordova or Capacitor
    if (this.platform.is("hybrid") && !galleryPhoto.webPath) {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: galleryPhoto.path,
      });
      return file.data;
    } else if (galleryPhoto.webPath) {
      const response = await fetch(galleryPhoto.webPath);
      const blob = await response.blob();
      return (await this.convertBlobToBase64(blob)) as string;
    }
  }
}
