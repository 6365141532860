import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { environment } from "../../environments/environment";
import * as CryptoJS from "crypto-js";
import { FileOpener } from "@capacitor-community/file-opener";

@Injectable({
  providedIn: "root",
})
export class ToolsService {
  constructor(private platform: Platform) {}

  titleCase(words) {
    if (words) {
      const tab = words.split(" ");
      const tab2 = [];
      tab.forEach((word) => {
        tab2.push(word.charAt(0) + word.substring(1).toLowerCase());
      });
      return tab2.join(" ");
    }
    return words;
  }

  // Convert a base64 string in a Blob according to the data and contentType.
  b64toBlob(b64Data, contentType) {
    b64Data = b64Data.split(",").pop();
    const rawData = atob(b64Data);
    const bytes = new Array(rawData.length);
    for (let x = 0; x < rawData.length; x++) {
      bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    return new Blob([arr], { type: contentType });
  }

  blobToB64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  async openDoc(id, nomMission = "test") {
    if (id.indexOf("id=") !== -1) {
      id = id.substring(id.indexOf("id=") + 3);
    }
    if (id.indexOf(".pdf") === -1) {
      id = `${id}.pdf`;
    }
    try {
      const result = await Filesystem.getUri({
        path: id,
        directory: Directory.Data,
      });
      await this.openFile(result, id);
    } catch (e) {
      console.log("error file", id, e);
    }
  }

  async openFile(result, path) {
    if (this.platform.is("cordova")) {
      FileOpener.open({ filePath: result.uri, contentType: "application/pdf" })
        .then(() => console.log("File is opened"))
        .catch((error) => console.log("Error openening file", error));
    } else {
      const file = await Filesystem.readFile({
        path,
        directory: Directory.Data,
      });
      const base64Response = await fetch(
        `data:application/pdf;base64,${file.data}`,
      );
      const blob = await base64Response.blob();
      window.open(URL.createObjectURL(blob), "_system");
    }
  }

  public addZero(chiffre) {
    if (chiffre.toString().length < 2) {
      chiffre = "0" + chiffre;
    }
    return chiffre;
  }

  public contains(obj, keyword, ...fields): boolean {
    for (const field of fields) {
      if (!obj[field]) {
        continue;
      }
      if (obj[field].toLowerCase().includes(keyword.toLowerCase())) {
        return true;
      }
    }
  }

  public trim(text: string, size: number) {
    return text.length > size ? `${text.substring(0, size - 3)}...` : text;
  }

  public decryptWithAES(ciphertext: string) {
    let passphrase = environment.cryptokey;
    let bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  public encryptWithAES(text: string) {
    let passphrase = environment.cryptokey;
    return CryptoJS.AES.encrypt(text, passphrase).toString();
  }
}
