import {Injectable, NgZone} from '@angular/core';
import {Network} from "@capacitor/network";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class NetworkService {
    statusChanged: Observable<boolean>;
    isOnline: boolean;

    private statusSubject = new BehaviorSubject<boolean>(false);
    constructor(
        private zone: NgZone
    ) {
        Network.getStatus().then(status => {
            this.isOnline = status.connected;
            this.statusSubject.next(status.connected);
        });
        this.statusChanged = this.statusSubject.asObservable();

        Network.addListener('networkStatusChange', (status) => {
            this.zone.run(() => {
                let wasOnline = this.isOnline;
                this.isOnline = status.connected;
                if (!wasOnline && status.connected) {
                    this.statusSubject.next(status.connected);
                }
            });
        });
    }
}
