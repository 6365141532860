import { Injectable } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { PhotoService } from "./photo.service";
import { ApiMissionService } from "./api-mission.service";
import { GeoServiceService } from "./geo-service.service";
import { ToolsService } from "./tools.service";
import { MissionsFiltresService } from "src/app/services/missions-filtres.service";
import { Events } from "./events.service";
import { ApiPhotoService } from "./api-photo.service";
import { Etat, Mission } from "../interfaces/mission";
import { AuthService } from "./auth.service";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Preferences as Storage } from "@capacitor/preferences";
import { KeepAwakeWeb } from "@capacitor-community/keep-awake/dist/esm/web";
import { format, parseISO } from "date-fns";
import { NetworkService } from "./network.service";
import { ModalSynchroComponent } from "../components/modal-synchro/modal-synchro.component";

export const StorageKey = {
  MISSION_STORAGE: "missions",
  MISSION_A_PLANIFIER: "fr_aplanifier",
};

@Injectable({
  providedIn: "root",
})
export class MissionService {
  public missions: Mission[] = [];
  public sortedMissions = [];
  public ordre = "decroissant";
  public listStatutsTelephoniques = [];
  public interventionDate;
  public syncing = false;
  public query;
  private filteredMissions = [];

  constructor(
    public photoService: PhotoService,
    private apiMissionService: ApiMissionService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private geoService: GeoServiceService,
    private tools: ToolsService,
    private missionsFiltres: MissionsFiltresService,
    private events: Events,
    private apiPhotoService: ApiPhotoService,
    private platform: Platform,
    private authService: AuthService,
    private insomnia: KeepAwakeWeb,
    private networkService: NetworkService,
    private toastController: ToastController,
  ) {}

  trierSelonListe(liste, missionmaj = null) {
    this.loadStorage(liste, missionmaj);
  }

  /* **************************************************************************
   *   Synchro avec le serveur
   */
  public async synchroniser(): Promise<boolean> {
    this.events.publish("debut envoi", {});
    return new Promise(async (resolve, reject) => {
      try {
        await this.insomnia.keepAwake();
      } catch (e) {
        console.log(e);
      }
      try {
        await this.envoyerLesMissions();
        this.events.publish("fin envoi", {});
        this.events.publish("changeConstatsAEnvoyer", 0);
        let ok = true;
        const missionsEnErreur = [];
        if (this.missions) {
          for (const mission of this.missions) {
            if (mission.etat === Etat.erreur) {
              ok = false;
              if (missionsEnErreur.indexOf(mission.fr_campagneidname) === -1) {
                missionsEnErreur.push(mission.fr_campagneidname);
              }
            }
            if (mission.constat.etatSignaturePromoteur === "erreur") {
              ok = false;
              if (missionsEnErreur.indexOf(mission.fr_campagneidname) === -1) {
                missionsEnErreur.push(mission.fr_campagneidname);
              }
            }
            if (mission.constat.etatSignatureClient === "erreur") {
              ok = false;
              if (missionsEnErreur.indexOf(mission.fr_campagneidname) === -1) {
                missionsEnErreur.push(mission.fr_campagneidname);
              }
            }
          }
        }

        if (ok) {
          await this.recupererDonnees();
          await this.filtrer("2");
          try {
            await this.insomnia.allowSleep();
          } catch (e) {
            console.log(e);
          }
          resolve(true);
        } else if (!ok) {
          this.apiMissionService.log(
            "erreur synchronisation",
            "popup une erreur est survenue",
          );
          let missionsStr = "";
          for (const mission of missionsEnErreur) {
            missionsStr += mission + ", ";
          }
          missionsStr = missionsStr.slice(0, -2);
          this.alertCtrl
            .create({
              header: "Une erreur est survenue",
              message:
                "Une erreur est survenue sur les constats suivants : <br/><br/> " +
                missionsStr +
                "<br/><br/> Si le problème persiste, contactez le support informatique.",
              buttons: [
                {
                  text: "Refaire un envoi des constats",
                  handler: () => {
                    this.synchroniser();
                  },
                },
                {
                  text: "Télécharger quand même (les constats seront écrasés)",
                  handler: async () => {
                    await this.recupererDonnees();
                    await this.filtrer("2");
                    try {
                      this.insomnia.allowSleep();
                    } catch (e) {
                      console.log(e);
                    } finally {
                      resolve(true);
                    }
                  },
                },
                {
                  text: "Annuler",
                  handler: () => {
                    this.events.publish("dismissSynchro", {});
                    resolve(false);
                  },
                },
              ],
            })
            .then((alert) => {
              alert.present();
            });
        }
      } catch (e) {
        console.log("erreur synchro", e);
        if (e.status == 404) {
          this.apiMissionService.log(
            "erreur récupération d'un document",
            JSON.stringify(e),
          );
        } else {
          this.apiMissionService.log("erreur synchro", JSON.stringify(e));
          await this.presentAlertErreurDeSynchro();
        }
        try {
          await this.insomnia.allowSleep();
        } catch (e) {
          console.log(e);
        }
        resolve(false);
      }
    });
  }

  async presentSyncroModal() {
    let loading = await this.modalCtrl.create({
      component: ModalSynchroComponent,
      cssClass: "synchro-modal",
      backdropDismiss: false,
    });
    await loading.present();
    let ok = await this.synchroniser();
    loading.dismiss();
    if (ok) {
      let toast = await this.toastController.create({
        message: "Synchronisation OK",
        duration: 2000,
      });
      toast.present();
    }
  }

  async recupererDonnees() {
    await this.recupererMission();
    await this.getPicklistValues();
    this.events.publish("fin recup missions");
    await this.getFichiers();
    this.events.publish("fin recup fichiers");
    await this.getPhotosAll();
  }

  async presentAlertErreurDeSynchro() {
    const alert = await this.alertCtrl.create({
      message:
        "Une erreur est survenue pendant la synchronisation. Veuillez réessayer ou contactez votre service informatique",
      buttons: ["OK"],
    });
    await alert.present();
  }

  public async envoyerLesMissions() {
    const data = await Storage.get({
      key: StorageKey.MISSION_STORAGE,
    });
    this.missions = JSON.parse(data.value);

    // ENVOYER LES MISSIONS
    if (this.missions) {
      for (const mission of this.missions) {
        for (const photo of mission.constat.genericAppointment.photosAvant) {
          await this.photoService.loadSaved(photo);
        }
        for (const photo of mission.constat.genericAppointment.photosApres) {
          await this.photoService.loadSaved(photo);
        }
        if (
          mission.etat === Etat.enAttenteEnvoi ||
          mission.etat === Etat.erreur ||
          mission.etat === Etat.envoiEnCours
        ) {
          await this.enregistrerConstat(
            mission,
            mission.constat.internalState,
            true,
          );
        }
      }
    }
  }

  async envoiSignatures(mission) {
    return new Promise<boolean>(async (resolve) => {
      let erreurSignature = false;
      if (
        mission.constat.signatureClient &&
        mission.constat.signatureClient.indexOf("base64") !== -1 &&
        (mission.constat.etatSignatureClient == "ajoutee" ||
          mission.constat.etatSignatureClient == "erreur")
      ) {
        const signature = mission.constat.signatureClient.split(",").pop();
        mission.constat.etatSignatureClient = "envoiEnCours";
        await this.apiPhotoService
          .envoyerSignature(signature, mission.activityid, false)
          .then(() => {
            mission.constat.etatSignatureClient = "envoyee";
            mission.constat.signatureClient = null;
          })
          .catch((err) => {
            mission.constat.etatSignatureClient = "erreur";
            erreurSignature = true;
            this.apiMissionService.log(
              "erreur envoi signature",
              JSON.stringify(err),
            );
          });
      }
      if (
        mission.constat.signaturePromoteur &&
        mission.constat.signaturePromoteur.indexOf("base64") !== -1 &&
        (mission.constat.etatSignaturePromoteur == "ajoutee" ||
          mission.constat.etatSignaturePromoteur == "erreur")
      ) {
        const signature = mission.constat.signaturePromoteur.split(",").pop();
        mission.constat.etatSignaturePromoteur = "envoiEnCours";
        await this.apiPhotoService
          .envoyerSignature(signature, mission.activityid, true)
          .then(() => {
            mission.constat.etatSignaturePromoteur = "envoyee";
            mission.constat.signaturePromoteur = null;
          })
          .catch((err) => {
            erreurSignature = true;
            mission.constat.etatSignaturePromoteur = "erreur";
            this.apiMissionService.log(
              "erreur envoi signature",
              JSON.stringify(err),
            );
          });
      }
      resolve(!erreurSignature);
    });
  }

  async envoiPhotos(photos) {
    return new Promise<boolean>(async (resolve) => {
      let ok = true;
      if (photos["photosAvant"]) {
        for (const photo of photos["photosAvant"].files) {
          if (photo.etat !== Etat.synchronisee) {
            photo.etat = Etat.envoiEnCours;
            let response = await this.apiPhotoService.envoyerPhoto(
              photo.file,
              photos["photosAvant"].idMission,
              "avant",
            );
            if (response.status == 200) {
              photo.etat = Etat.synchronisee;
            } else {
              photo.etat = Etat.erreur;
              ok = false;
              await this.apiMissionService.log(
                "erreur envoi photos avant",
                JSON.stringify(response),
              );
            }
          }
        }
      }
      if (photos["photosApres"]) {
        for (const photo of photos["photosApres"].files) {
          if (photo.etat !== Etat.synchronisee) {
            photo.etat = Etat.envoiEnCours;
            await this.apiPhotoService
              .envoyerPhoto(
                photo.file,
                photos["photosApres"].idMission,
                "apres",
              )
              .then(() => {
                photo.etat = Etat.synchronisee;
              })
              .catch((err) => {
                photo.etat = Etat.erreur;
                ok = false;
                this.apiMissionService.log(
                  "erreur envoi photos après",
                  JSON.stringify(err),
                );
              });
          }
        }
      }
      if (photos["apptAvant"]) {
        for (const file of photos["apptAvant"]) {
          for (const element of file.files) {
            if (element.etat !== Etat.synchronisee) {
              element.etat = Etat.envoiEnCours;
              await this.apiPhotoService
                .envoyerPhoto(element.file, file.idMission, "avant")
                .then(() => {
                  element.etat = Etat.synchronisee;
                })
                .catch((err) => {
                  element.etat = Etat.erreur;
                  ok = false;
                  this.apiMissionService.log(
                    "erreur envoi photos avant appt",
                    JSON.stringify(err),
                  );
                });
            }
          }
        }
      }
      if (photos["apptApres"]) {
        for (const file of photos["apptApres"]) {
          for (const element of file.files) {
            if (element.etat !== Etat.synchronisee) {
              element.etat = Etat.envoiEnCours;
              await this.apiPhotoService
                .envoyerPhoto(element.file, file.idMission, "apres")
                .then(() => {
                  element.etat = Etat.synchronisee;
                })
                .catch((err) => {
                  element.etat = Etat.erreur;
                  ok = false;
                  this.apiMissionService.log(
                    "erreur envoi photos après appt",
                    JSON.stringify(err),
                  );
                });
            }
          }
        }
      }
      resolve(ok);
    });
  }

  /*
   * Leo Machillot 10/03/2021
   * Envoyer un brouillon ou un constat
   * envoi: paramètre envoi depuis la synchro
   */
  async enregistrerConstat(mission, statut, envoi = false) {
    if (statut == 1) {
      mission.ux.disabledcard = true;
      mission.ux.saveBrouillon = true;
    }
    if (statut == 2) {
      mission.fr_statutmissionname = "Envoi en cours";
    }
    if (!this.networkService.isOnline) {
      // hors-ligne
      if (statut === 2) {
        mission.etat = Etat.enAttenteEnvoi;
      }
      await this.saveInStorage(mission);
      if (statut == 2) {
        let nb = 1;
        const data = await Storage.get({
          key: "fr_aenvoyer",
        });
        if (data && data.value) {
          nb += parseInt(data.value);
        }
        this.events.publish("changeConstatsAEnvoyer", nb);
      }
      return;
    }
    this.events.publish("envoi en cours", {
      message:
        "Envoi de la mission " +
        mission.fr_campagneidname +
        " (" +
        mission.PDV.name +
        ")",
    });
    mission.etat = Etat.envoiEnCours;
    // envoi en statut brouillon
    const promises = [];
    const photos = {};
    this.traitementMissions(mission, promises, photos);
    const data = await Storage.get({
      key: "filrouge_delete",
    });
    if (data && data.value) {
      const tab = JSON.parse(data.value);
      tab.forEach((photo) => {
        try {
          this.apiPhotoService.deletePhoto(photo);
        } catch (e) {
          console.log("erreur delete photo");
          this.apiMissionService.log("erreur delete photo", JSON.stringify(e));
        }
      });
      await Storage.remove({
        key: "filrouge_delete",
      });
    }
    await Promise.all(promises);
    const photosAvant = mission.constat.genericAppointment.photosAvant;
    const photosApres = mission.constat.genericAppointment.photosApres;
    const photosApptAvant = {};
    const photosApptApres = {};
    if (envoi) {
      mission.constat.genericAppointment.photosAvant = [];
      mission.constat.genericAppointment.photosApres = [];
      if (
        mission.constat.appointments &&
        mission.constat.appointments.length > 0
      ) {
        mission.constat.appointments.forEach((appt) => {
          photosApptAvant[appt.activityid] = appt.photosAvant;
          photosApptApres[appt.activityid] = appt.photosApres;
          appt.photosAvant = [];
          appt.photosApres = [];
        });
      }
    }
    // envoi signatures
    const signatureClient = mission.constat.signatureClient;
    const signaturePromoteur = mission.constat.signaturePromoteur;

    let okEnvoiSignature = await this.envoiSignatures(mission);
    this.events.publish("envoi en cours", {
      message: okEnvoiSignature
        ? "Envoi des signatures terminé"
        : "Erreur envoi signature",
    });

    let okEnvoiPhoto = await this.envoiPhotos(photos);
    this.events.publish("envoi en cours", {
      message: okEnvoiPhoto
        ? "Envoi des photos terminé"
        : "Erreur envoi photos",
    });

    if (okEnvoiPhoto && okEnvoiSignature) {
      if (statut == 1) {
        // brouillon
        mission.ux.disabledcard = true;
        mission.ux.saveBrouillon = true;
        const found = this.missions.find(
          (m) => m.activityid === mission.activityid,
        );
        found["ux"].disabledcard = true;
        found["ux"].saveBrouillon = true;
        try {
          const ok = await this.envoyerBrouillon(mission.constat);
          if (ok) {
            mission.etat = Etat.synchronisee;
            mission["ux"].disabledcard = false;
            mission["ux"].saveBrouillon = false;
            this.saveInStorage(mission);
            const found = this.missions.find(
              (m) => m.activityid === mission.activityid,
            );
            found.etat = Etat.synchronisee;
            found["ux"].disabledcard = false;
            found["ux"].saveBrouillon = false;
            this.events.publish("end traitement", found);
            this.saveInStorage(found);
            this.remettrePhotosSignatures(
              mission,
              signatureClient,
              signaturePromoteur,
              photosAvant,
              photosApres,
              photosApptAvant,
              photosApptApres,
            );
            await this.saveInStorage(mission);
          } else {
            mission.etat = Etat.erreur;
            mission["ux"].disabledcard = false;
            mission["ux"].saveBrouillon = false;
            const found = this.missions.find(
              (m) => m.activityid === mission.activityid,
            );
            found.etat = Etat.erreur;
            found["ux"].disabledcard = false;
            found["ux"].saveBrouillon = false;
            found.enBrouillon = statut == 1;
            this.saveInStorage(mission);
            this.remettrePhotosSignatures(
              mission,
              signatureClient,
              signaturePromoteur,
              photosAvant,
              photosApres,
              photosApptAvant,
              photosApptApres,
            );
            this.apiMissionService.log(
              "erreur envoi brouillon",
              JSON.stringify("erreur enregistrement brouillon"),
            );
          }
        } catch (e) {
          mission.etat = Etat.erreur;
          const found = this.missions.find(
            (m) => m.activityid === mission.activityid,
          );
          found.etat = Etat.erreur;
          mission["ux"].disabledcard = false;
          mission["ux"].saveBrouillon = false;
          found.enBrouillon = statut == 1;
          this.saveInStorage(mission);
          this.remettrePhotosSignatures(
            mission,
            signaturePromoteur,
            signatureClient,
            photosAvant,
            photosApres,
            photosApptAvant,
            photosApptApres,
          );
          this.apiMissionService.log(
            "erreur envoi brouillon",
            JSON.stringify(e),
          );
        }
      } else {
        try {
          mission.fr_statutmissionname = "Envoi en cours";
          const found = this.missions.find(
            (m) => m.activityid === mission.activityid,
          );
          found.fr_statutmissionname = "Envoi en cours";
          const retour = await this.sendConstatNew(mission.constat);
          mission.enBrouillon = false;
          if (retour) {
            mission.etat = Etat.synchronisee;
            const found = this.missions.find(
              (m) => m.activityid === mission.activityid,
            );
            found.etat = Etat.synchronisee;
            mission.fr_statutmissionname = "Traitement en cours";
            found.fr_statutmissionname = "Traitement en cours";
          } else {
            mission.etat = Etat.erreur;
            mission.fr_statutmissionname = "Echec";
            const found = this.missions.find(
              (m) => m.activityid === mission.activityid,
            );
            found.etat = Etat.erreur;
            found.fr_statutmissionname = "Echec";
            found["ux"].disabledcard = false;
            mission.ux.disabledcard = false;
          }
          this.remettrePhotosSignatures(
            mission,
            signaturePromoteur,
            signatureClient,
            photosAvant,
            photosApres,
            photosApptAvant,
            photosApptApres,
          );
          mission.fr_statutmission = "315940018";
          if (mission.fr_statutmissionname != "Echec") {
            mission.ux.disabledcard = true;
          }
          if (mission.constat?.genericAppointment.fr_motifreport) {
            mission.fr_statutmissionname = "Reportée";
            mission.ux.disabledcard = true;
            mission.fr_statutmission = "315940005";
          }
          await this.saveInStorage(mission);
        } catch (e) {
          await this.envoyerBrouillon(mission.constat);
          mission.etat = Etat.erreur;
          const found = this.missions.find(
            (m) => m.activityid === mission.activityid,
          );
          found.etat = Etat.erreur;
          found.fr_statutmissionname = "Echec";
          this.saveInStorage(mission);
          this.remettrePhotosSignatures(
            mission,
            signaturePromoteur,
            signatureClient,
            photosAvant,
            photosApres,
            photosApptAvant,
            photosApptApres,
          );
          this.apiMissionService.log("erreur envoi mission", JSON.stringify(e));
        }
      }
    } else {
      mission.etat = Etat.erreur;
      const found = this.missions.find(
        (m) => m.activityid === mission.activityid,
      );
      found.etat = Etat.erreur;
      this.saveInStorage(found);
      await this.envoyerBrouillon(mission.constat);
    }
  }

  remettrePhotosSignatures(
    mission,
    signatureClient,
    signaturePromoteur,
    photosAvant,
    photosApres,
    photosApptAvant,
    photosApptApres,
  ) {
    mission.constat.signatureClient = signatureClient;
    mission.constat.signaturePromoteur = signaturePromoteur;
    mission.constat.genericAppointment.photosAvant = photosAvant;
    mission.constat.genericAppointment.photosApres = photosApres;

    if (mission.constat.appointments) {
      mission.constat.appointments.forEach((element) => {
        element.photosAvant = photosApptAvant[element.activityid];
        element.photosApres = photosApptApres[element.activityid];
      });
    }
  }

  async envoyerBrouillon(constat) {
    let ok = false;
    try {
      const result = await this.apiMissionService.envoyerBrouillon(constat);
      if (result.status == 200) {
        ok = true;
      }
    } catch (e) {
      console.log(e);
      this.apiMissionService.log("erreur envoi brouillon", JSON.stringify(e));
      ok = false;
    }
    return ok;
  }

  async sendConstatNew(constat) {
    let ok = false;
    try {
      const retour = await this.apiMissionService.sendConstatNew(constat);
      if (retour.status == 200) {
        ok = true;
      }
    } catch (e) {
      ok = false;
      this.apiMissionService.log("erreur envoi constat", JSON.stringify(e));
    }
    return ok;
  }

  async getMissionsRealisees() {
    this.sortedMissions = [];
    const data = await this.apiMissionService.getMissionsRealiseesApi();
    this.recupDonnees(data, "4", false);
  }

  async getMissionsAPlanifier() {
    return this.apiMissionService.getMissionsAPlanifierApi();
  }

  public async recupererMission() {
    const data = await this.apiMissionService.getMissionsOffline();
    this.recupDonnees(data, "2", true);
  }

  public async recupererAplanifier() {
    const data = await this.getMissionsAPlanifier();
    this.recupDonnees(data, "1", true);
  }

  public recupDonnees(data, typemission, save = false) {
    this.missions = [];
    for (const mission of data as Array<any>) {
      // missions en cours
      let missionPush = {
        distance: this.getDistancePdv(mission),
        etat: Etat.synchronisee,
        type: "mission",
        PDV: mission.PDV,
        fr_campagneid: mission.fr_campagneid,
        fr_campagneidname: mission.Campagne?.name,
        fr_dateintervention: mission.fr_dateintervention,
        fr_datedinterventionproposee: mission.fr_datedinterventionproposee,
        fr_datelimitederealisation: mission.fr_datelimitederealisation,
        strDateIntervention: mission.strDateIntervention,
        fr_statutmission: mission.fr_statutmission,
        fr_statutmissionname: mission.fr_statutmissionname,
        activityid: mission.activityid,
        prioritycode: mission.prioritycode,
        fr_nomresponsablepdv: mission.fr_nomresponsablepdv,
        Campagne: mission.Campagne,
        fr_lienods: mission.fr_lienods,
        fr_commentairedupromoteur: mission.fr_commentairedupromoteur,
        fr_commentairepourpromoteur: mission.fr_commentairepourpromoteur,
        repeat: true,
        fr_motifreport: mission.fr_motifreport,
        fr_azurefileodsid:
          mission.Constat?.genericAppointment.fr_azurefileodsid,
        okPhotos: mission.Constat.OkPhotos,
        okSignatures: mission.Constat.OkSignatures,
        new_tempsmoyendelaction:
          mission.Constat?.genericAppointment.new_tempsmoyendelaction,
      };
      if (mission.PDV) {
        mission.PDV.email = mission.PDV.emailaddress1;
      }
      if (mission.Constat) {
        if (mission.PDV) {
          mission.PDV.email = mission.PDV.emailaddress1;
        }
        missionPush = {
          distance: this.getDistancePdv(mission),
          etat: Etat.synchronisee,
          type: "mission",
          PDV: mission.PDV,
          fr_campagneid: mission.Constat?.campaignid,
          fr_campagneidname: mission.Campagne?.name,
          fr_dateintervention:
            mission.Constat?.genericAppointment.fr_dateintervention,
          fr_datedinterventionproposee:
            mission.Constat?.genericAppointment.fr_datedinterventionproposee,
          fr_datelimitederealisation:
            mission.Constat?.genericAppointment.fr_datelimitederealisation,
          strDateIntervention:
            mission.Constat?.genericAppointment.strDateIntervention,
          fr_statutmission:
            mission.Constat?.genericAppointment.fr_statutmission,
          fr_statutmissionname:
            mission.Constat?.genericAppointment.fr_statutmissionname,
          activityid: mission.Constat?.genericAppointment.activityid,
          prioritycode: mission.Constat?.genericAppointment.prioritycode,
          fr_nomresponsablepdv:
            mission.Constat?.genericAppointment.fr_nomresponsablepdv,
          Campagne: mission.Campagne,
          fr_lienods: mission.Constat?.genericAppointment.fr_lienods, // recuperer dans general apptment ??
          fr_commentairedupromoteur:
            mission.Constat?.genericAppointment.fr_commentairedupromoteur,
          fr_commentairepourpromoteur:
            mission.Constat?.genericAppointment.fr_commentairepourpromoteur,
          repeat: true,
          fr_motifreport: mission.Constat?.genericAppointment.fr_motifreport,
          fr_azurefileodsid:
            mission.Constat?.genericAppointment.fr_azurefileodsid,
          okPhotos: mission.Constat?.OkPhotos,
          okSignatures: mission.Constat?.OkSignatures,
          new_tempsmoyendelaction:
            mission.Constat?.genericAppointment.new_tempsmoyendelaction,
        };
        missionPush["constat"] = {
          CodeRegroupement: mission.Constat.CodeRegroupement,
          EmailPDV: mission.Constat.EmailPDV,
          promoteurId: mission.Constat.promoteurId,
          pdvid: mission.Constat.pdvid,
          campaignid: mission.Constat.campaignid,
          fr_nomresponsablepdv:
            mission.Constat?.genericAppointment.fr_nomresponsablepdv,
          appointments: [],
          constatId: mission.Constat.constatId,
          fr_nomresponsablepdvobligatoire:
            mission.Constat.fr_nomresponsablepdvobligatoire,
          fr_signatureobligatoirepdv:
            mission.Constat.fr_signatureobligatoirepdv,
          fr_signatureobligatoireintervenant:
            mission.Constat.fr_signatureobligatoireintervenant,
          internalState: mission.Constat.internalState,
          nomresponsablepdv: mission.Constat.nomresponsablepdv,
          signatureClient: mission.Constat.signatureClient,
          signaturePromoteur: mission.Constat.signaturePromoteur,
          genericAppointment: this.fillAppointement(
            mission.Constat.genericAppointment,
          ),
        };
      }
      if (
        mission.Constat &&
        mission.Constat.appointments &&
        mission.Constat.appointments.length > 0
      ) {
        for (const appt of mission.Constat.appointments) {
          const apptPush = this.fillAppointement(appt);
          missionPush["constat"].appointments.push(apptPush);
        }
      }
      if (
        mission.Constat &&
        mission.Constat.appointments &&
        mission.Constat.appointments.length > 0
      ) {
        for (const appt of mission.Constat.appointments) {
          const i = mission.Constat.appointments.indexOf(appt);
          for (const photo of appt.photos) {
            // tslint:disable-next-line: triple-equals
            if (photo.fr_avantapres == 315940000) {
              if (!missionPush["constat"].appointments[i].photosAvant) {
                missionPush["constat"].appointments[i].photosAvant = [];
              }
              missionPush["constat"].appointments[i].photosAvant.push({
                fr_photoid: photo.fr_photoid,
                isThumbnail: true,
              });
            } else {
              if (!missionPush["constat"].appointments[i].photosApres) {
                missionPush["constat"].appointments[i].photosApres = [];
              }
              missionPush["constat"].appointments[i].photosApres.push({
                fr_photoid: photo.fr_photoid,
                isThumbnail: true,
              });
            }
          }
        }
      }
      this.missions.push(missionPush);
    }

    if (save) {
      switch (typemission) {
        case "1":
          this.saveInStorage(null, StorageKey.MISSION_A_PLANIFIER);
          break;
        case "2":
          this.saveInStorage(null, StorageKey.MISSION_STORAGE);
          break;
      }
    }
    this.sortedMissions = [];
    this.trierParDatePDV(typemission);
  }

  fillAppointement(appointment) {
    var appt = {
      subject: appointment.subject,
      activityid: appointment.activityid,
      fr_dateintervention: appointment.fr_dateintervention,
      fr_datedinterventionproposee: appointment.fr_datedinterventionproposee,
      fr_datelimitederealisation: appointment.fr_datelimitederealisation,
      fr_datereport: appointment.fr_datereport,
      fr_motifrefus: appointment.fr_motifrefus,
      fr_motifrefusname: appointment.fr_motifrefusname,
      fr_motifreport: appointment.fr_motifreport,
      fr_motifreportname: appointment.fr_motifreportname,
      fr_prisedephotoapres: appointment.fr_prisedephotoapres,
      fr_prisedephotoavant: appointment.fr_prisedephotoavant,
      fr_statut_telephonique: appointment.fr_statut_telephonique ?? 315940000,
      fr_statut_telephoniquename: !appointment.fr_statut_telephoniquename
        ? "Validation en cours"
        : appointment.fr_statut_telephoniquename === "PDV contacté"
        ? "Validé"
        : appointment.fr_statut_telephoniquename,
      fr_statutmission: appointment.fr_statutmission,
      fr_statutmissionname: appointment.fr_statutmissionname,
      missionGenerique: appointment.missionGenerique,
      motifsRefus: appointment.motifsRefus,
      motifsReport: appointment.motifsReport,
      qrs: appointment.qrs,
      fr_commentairedupromoteur: appointment.fr_commentairedupromoteur,
      fr_commentairepourpromoteur: appointment.fr_commentairepourpromoteur,
      fr_commentairemotifreport: appointment.fr_commentairemotifreport,
      fr_commentairemotifsrefus: appointment.fr_commentairemotifsrefus,
      fr_responsablerefu: appointment.fr_responsablerefus,
      internalState: appointment.internalState,
      photosAvant: [],
      photosApres: [],
      photos: appointment.photos,
      fr_commentaireappel: appointment.fr_commentaireappel,
      new_tempsmoyendelaction: appointment.new_tempsmoyendelaction,
    };
    for (let q of appt.qrs?.filter((v) => v.q_fr_typedereponse === 315940003)) {
      q.selectedValues = q.valeursReponse
        ?.filter((v) => v.Selected)
        ?.map((v) => v.Value);
    }
    return appt;
  }

  filtrerParMoisAnneeFiltered(typemission) {
    if (
      typemission === "4" &&
      this.missionsFiltres.mois != null &&
      this.missionsFiltres.annee
    ) {
      const fmois = this.tools.addZero(this.missionsFiltres.mois + 1);
      this.filteredMissions = this.filteredMissions.filter((item) => {
        if (item.strDateIntervention) {
          const tab = item.strDateIntervention.split("-");
          const annee = tab[0];
          const mois = tab[1];
          return mois == fmois && annee == this.missionsFiltres.annee;
        }
        return false;
      });
    }
  }

  async filtrer(typeMission, missionmaj = null) {
    await this.loadStorage(typeMission, missionmaj);
    if (this.query) {
      this.missions = this.missions.filter(
        (d) =>
          d.PDV.name.toLowerCase().indexOf(this.query) > -1 ||
          d.PDV.address1_postalcode.toLowerCase().indexOf(this.query) > -1 ||
          d.PDV.address1_city.toLowerCase().indexOf(this.query) > -1 ||
          d.PDV.address1_line1.toLowerCase().indexOf(this.query) > -1 ||
          d.PDV.address1_line1.toLowerCase().indexOf(this.query) > -1 ||
          d.PDV.fr_ugavuecomptename.toLowerCase().indexOf(this.query) > -1 ||
          d.PDV.accountnumber.toLowerCase().indexOf(this.query) > -1 ||
          d.Campagne.name.toLowerCase().indexOf(this.query) > -1,
      );
    }

    if (this.interventionDate && typeMission == "2") {
      this.missions = this.missions.filter(
        (m) =>
          m.fr_dateintervention &&
          format(parseISO(this.interventionDate), "dd-MM-yyyy") ===
            format(parseISO(m.fr_dateintervention), "dd-MM-yyyy"),
      );
    }

    if (this.missionsFiltres.selectedClients.length) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.selectedClients.includes(
          mission.Campagne.fr_clientid,
        );
      });
    }

    if (this.missionsFiltres.selectedPdvs.length) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.selectedPdvs.includes(
          mission.PDV.accountid,
        );
      });
    }

    if (this.missionsFiltres.selectedCampagnes.length) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.selectedCampagnes.includes(
          mission.fr_campagneid,
        );
      });
    }

    if (this.missionsFiltres.selectedVilles.length) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.selectedVilles.includes(
          mission.PDV.address1_postalcode,
        );
      });
    }

    if (this.missionsFiltres.selectedUgas.length) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.selectedUgas.includes(
          mission.PDV.fr_ugavuecompteid,
        );
      });
    }

    if (this.missionsFiltres.priorite.length) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.priorite.includes(mission.prioritycode);
      });
    }

    if (this.missionsFiltres.selectedStatuts.length) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.selectedStatuts.includes(
          mission.constat.genericAppointment.fr_statutmission,
        );
      });
    }

    if (this.missionsFiltres.priserdv !== null) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.priserdv
          ? mission.Campagne.fr_prisederdvobligatoire
          : !mission.Campagne.fr_prisederdvobligatoire;
      });
    }

    if (this.missionsFiltres.datelimite !== null) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.datelimite
          ? mission.constat.genericAppointment.fr_datelimitederealisation
          : !mission.constat.genericAppointment.fr_datelimitederealisation;
      });
    }

    if (this.missionsFiltres.dateplanif !== null && typeMission == "1") {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.dateplanif
          ? mission.constat.genericAppointment.fr_datedinterventionproposee
          : !mission.constat.genericAppointment.fr_datedinterventionproposee;
      });
    }

    if (this.missionsFiltres.dispositifs.length) {
      if (this.missionsFiltres.dispositifs.indexOf("tg") !== -1) {
        this.missions = this.missions.filter((mission) => {
          return mission.Campagne.new_tg;
        });
      } else if (this.missionsFiltres.dispositifs.indexOf("merch") !== -1) {
        this.missions = this.missions.filter((mission) => {
          return mission.Campagne.new_merch;
        });
      } else if (this.missionsFiltres.dispositifs.indexOf("vitrine") !== -1) {
        this.missions = this.missions.filter((mission) => {
          return mission.Campagne.new_vitrine;
        });
      } else if (this.missionsFiltres.dispositifs.indexOf("autres") !== -1) {
        this.missions = this.missions.filter((mission) => {
          return mission.Campagne.new_autresdispositifs;
        });
      }
    }

    if (this.missionsFiltres.fildeleau !== null) {
      this.missions = this.missions.filter((mission) => {
        return this.missionsFiltres.fildeleau
          ? mission.Campagne.typecode == "2"
          : !mission.Campagne.typecode || mission.Campagne.typecode == "1";
      });
    }

    // enlever mission new
    if (this.missions) {
      this.missions = this.missions.filter((mission) => {
        return mission.constat.constatId !== "00000000000000000000000000000000";
      });
    }

    this.sortedMissions = [];
    this.trierParDatePDV(typeMission);
  }

  // Calculer la distance entre l'utilisateurice et le poind de vente
  getDistancePdv(mission) {
    if (
      mission &&
      this.geoService.posLat &&
      this.geoService.posLng &&
      mission.PDV
    ) {
      // tslint:disable-next-line: max-line-length
      const distance = this.geoService.get_distance_m(
        this.geoService.posLat,
        this.geoService.posLng,
        mission.PDV.address1_latitude,
        mission.PDV.address1_longitude,
      );
      return (distance / 1000).toFixed(1);
    } else {
      return null;
    }
  }

  public async loadStorage(typemission = "2", missionmaj = null) {
    if (typemission === "2") {
      // Récupération du Storage
      const data = await Storage.get({
        key: StorageKey.MISSION_STORAGE,
      });
      this.missions = JSON.parse(data.value);
      console.log("missions in storage", this.missions);
      if (missionmaj) {
        const found = this.missions.find(
          (mission) => mission.activityid == missionmaj,
        );
        if (found) {
          found.fr_statutmission = 315940018;
          if (found.fr_statutmissionname != "Echec") {
            found.fr_statutmissionname = "Traitement en cours";
            found["ux"].disabledcard = true;
          }
        }
      }
      // Chargement des images
      if (this.missions) {
        for (const mission of this.missions) {
          for (const photo of mission.constat.genericAppointment.photosAvant) {
            await this.photoService.loadSaved(photo);
          }
          for (const photo of mission.constat.genericAppointment.photosApres) {
            await this.photoService.loadSaved(photo);
          }
        }
      }
    } else if (typemission === "1") {
      const data = await Storage.get({
        key: StorageKey.MISSION_A_PLANIFIER,
      });
      if (data && data.value) {
        this.missions = JSON.parse(data.value);
      }
    }
    this.trierParDatePDV(typemission);

    Storage.get({
      key: "fr_picklist_values",
    }).then((data) => {
      if (data && data.value) {
        let picklists = JSON.parse(data.value);
        this.listStatutsTelephoniques =
          picklists.find((p) => p.list === "statutstelephoniques")?.data ?? [];
      }
    });
  }

  /* **************************************************************************
   *   Gestion du storage
   */

  public async saveInStorage(
    mission = null,
    typeMission = StorageKey.MISSION_STORAGE,
  ) {
    if (!mission) {
      try {
        await Storage.set({
          key: typeMission,
          value: JSON.stringify(this.missions),
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await Storage.get({
          key: typeMission,
        }).then(async (value) => {
          const lstMissions = JSON.parse(value.value);
          const found = lstMissions.find((m) => {
            return m.activityid === mission.activityid;
          });
          if (found) {
            lstMissions.splice(lstMissions.indexOf(found), 1);
          }
          lstMissions.push(mission);
          this.missions = lstMissions;
          await Storage.set({
            key: typeMission,
            value: JSON.stringify(this.missions),
          });
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  async getListPdvs(date) {
    const pdvs = [];
    await this.loadStorage();
    for (const mission of this.missions) {
      mission.PDV["statut"] = mission.fr_statutmission;
      if (
        pdvs.indexOf(mission.PDV) === -1 &&
        mission.fr_statutmission !== 315940018
      ) {
        if (mission.fr_dateintervention) {
          if (
            date &&
            format(parseISO(date), "dd-MM-yyyy") ==
              format(parseISO(mission.fr_dateintervention), "dd-MM-yyyy")
          ) {
            this.addPdv(pdvs, mission.PDV);
          } else if (!date) {
            this.addPdv(pdvs, mission.PDV);
          }
        }
      }
    }
    return pdvs;
  }

  addPdv(pdvs, pdv) {
    pdvs.reduce((acc, curr) => {
      if (curr.accountid === pdv.accountid) {
        curr.nbMissions++;
        return true;
      }
      return acc;
    }, false) || pdvs.push({ ...pdv, nbMissions: 1 });
  }

  /* **************************************************************************
   *   Tri des missions
   */
  trierParDatePDV(typemission) {
    this.sortedMissions = [];
    this.filteredMissions = [];
    if (this.missions) {
      for (const mission of this.missions) {
        this.filteredMissions.push(mission);
      }
      // trier par date puis pdv
      this.filteredMissions.sort((a, b) => {
        if (typemission == "1") {
          if (a.fr_datelimitederealisation && b.fr_datelimitederealisation) {
            if (a.fr_datelimitederealisation > b.fr_datelimitederealisation) {
              return 1;
            } else if (
              a.fr_datelimitederealisation < b.fr_datelimitederealisation
            ) {
              return -1;
            }
          }
          if (a.Campagne.startDate < b.Campagne.startDate) {
            return -1;
          } else if (a.Campagne.startDate > b.Campagne.startDate) {
            return 1;
          }
        } else if (a.strDateIntervention || b.strDateIntervention) {
          if (a.strDateIntervention === null) {
            if (this.ordre === "croissant" || typemission == "2") {
              return -1;
            } else {
              return 1;
            }
          }
          if (b.strDateIntervention === null) {
            if (this.ordre === "croissant" || typemission == "2") {
              return 1;
            } else {
              return -1;
            }
          }
          if (a.strDateIntervention < b.strDateIntervention) {
            if (this.ordre === "croissant" || typemission == "2") {
              return -1;
            } else {
              return 1;
            }
          }
          if (a.strDateIntervention > b.strDateIntervention) {
            if (this.ordre === "croissant" || typemission == "2") {
              return 1;
            } else {
              return -1;
            }
          }
        }
        if (a.PDV.name > b.PDV.name) {
          return 1;
        }
        if (a.PDV.name < b.PDV.name) {
          return -1;
        }
      });
      this.filtrerParMoisAnneeFiltered(typemission);
      // On insère dans la liste à afficher les dates et les PDV
      let datePrecedente = null;
      let pdvPrecedent = null;
      for (const mission of this.filteredMissions) {
        if (mission.strDateIntervention !== datePrecedente) {
          this.sortedMissions.push({
            type: "date",
            title: mission.strDateIntervention,
            ux: { type: "card-date", disabledcard: false },
          });
          pdvPrecedent = null;
        }
        let saveBrouillon = false;
        if (mission.ux && mission.ux.saveBrouillon) {
          saveBrouillon = true;
        }
        if (mission.PDV.accountid !== pdvPrecedent) {
          const nbMission = this.filteredMissions.filter(
            (m) => m.PDV.accountid === mission.PDV.accountid,
          );
          this.sortedMissions.push({
            type: "pdv",
            date: mission.strDateIntervention,
            accountid: mission.PDV.accountid,
            fr_ugavuecomptename: mission.PDV.fr_ugavuecomptename,
            name: mission.PDV.name,
            address1_line1: mission.PDV.address1_line1,
            address1_line2: mission.PDV.address1_line2,
            address1_city: mission.PDV.address1_city,
            address1_postalcode: mission.PDV.address1_postalcode,
            telephone1: mission.PDV.telephone1,
            email: mission.PDV.email,
            nbMissionTotal: nbMission.length,
            distance: mission.distance,
            accountnumber: mission.PDV.accountnumber,
            ux: { type: "card-pdv" },
          });
        }
        if (!mission.ux) {
          mission.ux = {};
        }
        mission.ux.saveBrouillon = saveBrouillon;
        mission.ux.disabledcard =
          mission.ux.disabledcard ||
          mission.fr_statutmissionname == "Traitement en cours" ||
          mission.fr_statutmissionname == "Envoi en cours" ||
          mission.fr_statutmissionname == "Reportée" ||
          mission.fr_statutmissionname == "Refusée" ||
          mission.fr_statutmission == "315940012" ||
          mission.etat === Etat.enAttenteEnvoi;
        // tslint:disable-next-line: max-line-length
        mission.ux.commentaires =
          mission.constat &&
          (mission.constat.genericAppointment.fr_commentairepourpromoteur ||
            mission.constat.genericAppointment.fr_commentaireappel);
        mission.ux.priority =
          mission.prioritycode == 0
            ? 3
            : mission.prioritycode == 1
            ? 2
            : mission.prioritycode == 2
            ? 1
            : "";
        // tslint:disable-next-line: max-line-length
        mission.ux.type =
          mission.prioritycode != 1
            ? "priorite card-" + mission.type
            : "card-" + mission.type;
        this.sortedMissions.push(mission);
        datePrecedente = mission.strDateIntervention;
        pdvPrecedent = mission.PDV.accountid;
      }
    }
  }

  public async getMission(id) {
    if (!this.missions || this.missions.length <= 0) {
      await this.loadStorage();
    }
    const mission = this.missions.find((m) => m.activityid === id);
    if (mission) {
      return mission;
    } else {
      return null;
    }
  }

  /* **************************************************************************
   * GEstion d'une mission
   */

  async addSignature(mission, signataire, signature) {
    if (signataire === "client") {
      mission.constat.signatureClient = signature;
      mission.constat.etatSignatureClient = "ajoutee";
    } else if (signataire === "intervenant") {
      mission.constat.signaturePromoteur = signature;
      mission.constat.etatSignaturePromoteur = "ajoutee";
    }
  }

  public async addPhoto(mission, type, appt = null, campagnenew = false) {
    const photos = await this.photoService.addPhoto(
      mission.activityid,
      type,
      campagnenew,
    );
    for (const p of photos) {
      if (!appt) {
        switch (type) {
          case "avant":
            if (!mission.constat.genericAppointment.photosAvant) {
              mission.constat.genericAppointment.photosAvant = [];
            }
            mission.constat.genericAppointment.photosAvant.push(p);
            break;
          case "apres":
            if (!mission.constat.genericAppointment.photosApres) {
              mission.constat.genericAppointment.photosApres = [];
            }
            mission.constat.genericAppointment.photosApres.push(p);
            break;
        }
      } else {
        const index = mission.constat.appointments.indexOf(appt);
        switch (type) {
          case "avant":
            if (!mission.constat.appointments[index].photosAvant) {
              mission.constat.appointments[index].photosAvant = [];
            }
            mission.constat.appointments[index].photosAvant.push(p);
            break;
          case "apres":
            if (!mission.constat.appointments[index].photosApres) {
              mission.constat.appointments[index].photosApres = [];
            }
            mission.constat.appointments[index].photosApres.push(p);
            break;
        }
      }
    }
    this.saveInStorage();
  }

  /*
   * Récupère toutes les photos des missions de l'utilisateur
   * pour les stoquer en locale et les rendre disponibles
   * hors connexion
   *
   * Jonathan Rossi - 2021-02-19
   * Création
   *
   */
  public async getPhotosAll() {
    let listeImages = [];
    const data = await Storage.get({
      key: "fr_images_registered",
    });
    if (data && data.value) {
      listeImages = JSON.parse(data.value);
    }
    for (const mission of this.missions) {
      const index = this.missions.indexOf(mission);
      for (const photo of mission.constat.genericAppointment.photos) {
        const i = mission.constat.genericAppointment.photos.indexOf(photo);
        const p = listeImages.find((p) => p.fr_photoid === photo.fr_photoid);
        if (!p) {
          try {
            let photo2 = await this.getPhotos(photo.fr_photoid);
            if (photo2) {
              photo2["fr_photoid"] = photo.fr_photoid;
              photo2["fr_avantapres"] = photo.fr_avantapres;
              photo2["isThumbnail"] = true;
              this.missions[index].constat.genericAppointment.photos[i] =
                photo2;
              listeImages.push(photo2);
            }
          } catch (e) {
            console.log("erreur photo", e);
          }
        } else {
          this.missions[index].constat.genericAppointment.photos[i] = p;
        }
      }
      /* Telecharger photos modeles de missions */
      if (
        mission.constat.appointments &&
        mission.constat.appointments.length > 0
      ) {
        for (const appt of mission.constat.appointments) {
          const indexAppt = mission.constat.appointments.indexOf(appt);
          if (appt.photos) {
            for (const photo of appt.photos) {
              const i = appt.photos.indexOf(photo);

              const p = listeImages.find(
                (p) => p.fr_photoid === photo.fr_photoid,
              );
              if (!p) {
                try {
                  let photo2 = await this.getPhotos(photo.fr_photoid);
                  if (photo2) {
                    photo2["fr_photoid"] = photo.fr_photoid;
                    photo2["fr_avantapres"] = photo.fr_avantapres;
                    photo2["isThumbnail"] = true;
                    this.missions[index].constat.appointments[indexAppt].photos[
                      i
                    ] = photo2;
                    listeImages.push(photo2);
                  }
                } catch (e) {
                  console.log("erreur photo", e);
                }
              } else {
                this.missions[index].constat.appointments[indexAppt].photos[i] =
                  p;
              }
            }
          }
        }
      }
      Storage.set({
        key: "fr_images_registered",
        value: JSON.stringify(listeImages),
      });
    }
    this.saveInStorage();
  }

  async getPhotos(id) {
    try {
      const photo = await this.apiMissionService.getPhoto(id);
      return await this.photoService.savePictureFromApi(id, photo);
    } catch (e) {
      this.apiMissionService.log(
        "erreur telechargement photo",
        JSON.stringify(e),
      );
      return null;
    }
  }

  /*
   * Récupération des fichiers pdf
   *
   */
  async getFichiers() {
    const data = await Storage.get({
      key: "fr_list_fichiers",
    });
    let listeFichiers = [];
    if (data && data.value) {
      listeFichiers = JSON.parse(data.value);
    }
    for (const mission of this.missions) {
      if (
        mission.Campagne.fr_azurecdcid &&
        listeFichiers.indexOf(mission.Campagne.fr_azurecdcid) === -1
      ) {
        this.events.publish("recup fichier", {
          type: "CDC",
          campagne: mission.Campagne.name,
        });
        const fichier = await this.apiMissionService.getFichier(
          mission.Campagne.fr_azurecdcid,
        );
        if (fichier) {
          const b64 = await this.photoService.convertBlobToBase64(fichier);
          await Filesystem.writeFile({
            path: mission.Campagne.fr_azurecdcid + ".pdf",
            data: b64 as string,
            directory: Directory.Data,
          })
            .then((result) => {
              console.log("Wrote file", result);
            })
            .catch((error) => console.log(error));
          listeFichiers.push(mission.Campagne.fr_azurecdcid);
        }
      }
      if (
        mission.Campagne.fr_azurefsoid &&
        listeFichiers.indexOf(mission.Campagne.fr_azurefsoid) === -1
      ) {
        this.events.publish("recup fichier", {
          type: "FSO",
          campagne: mission.Campagne.name,
        });
        const fichier = await this.apiMissionService.getFichier(
          mission.Campagne.fr_azurefsoid,
        );
        if (fichier) {
          const b64 = await this.photoService.convertBlobToBase64(fichier);
          await Filesystem.writeFile({
            path: mission.Campagne.fr_azurefsoid + ".pdf",
            data: b64 as string,
            directory: Directory.Data,
          })
            .then((result) => {
              console.log("Wrote file", result);
            })
            .catch((error) => console.log(error));
          listeFichiers.push(mission.Campagne.fr_azurefsoid);
        }
      }
      if (
        mission.fr_azurefileodsid &&
        listeFichiers.indexOf(mission.fr_azurefileodsid) === -1
      ) {
        this.events.publish("recup fichier", {
          type: "ODS",
          campagne: mission.Campagne.name,
        });
        const fichier = await this.apiMissionService.getFichier(
          mission.fr_azurefileodsid,
        );
        if (fichier) {
          const b64 = await this.photoService.convertBlobToBase64(fichier);
          await Filesystem.writeFile({
            path: mission.fr_azurefileodsid + ".pdf",
            data: b64 as string,
            directory: Directory.Data,
          })
            .then((result) => {
              console.log("Wrote file", result);
            })
            .catch((error) => console.log(error));
          listeFichiers.push(mission.fr_azurefileodsid);
        }
      }
    }
    // Fichiers campagne new
    const user = await this.authService.getUser();
    try {
      const typeliste = user["role"] == "prestataire" ? 2 : 1;
      const campagnes = (await this.apiMissionService.getListCampagnes(
        typeliste,
      )) as Array<any>;
      for (const campagne of campagnes) {
        if (campagne.fr_liencahierdescharges) {
          const id = campagne.fr_liencahierdescharges.substr(
            campagne.fr_liencahierdescharges.indexOf("id=") + 3,
          );
          if (listeFichiers.indexOf(id) === -1) {
            const fichier = await this.apiMissionService.getFichier(id);
            if (fichier) {
              const b64 = await this.photoService.convertBlobToBase64(fichier);
              id.replace(".pdf", "");
              await Filesystem.writeFile({
                path: id + ".pdf",
                data: b64 as string,
                directory: Directory.Data,
              })
                .then((result) => {
                  console.log("Wrote file", result);
                })
                .catch((error) => console.log(error));
              listeFichiers.push(id);
            }
          }
        }
        if (campagne.fr_lienfichesellout) {
          const id = campagne.fr_lienfichesellout.substr(
            campagne.fr_lienfichesellout.indexOf("id=") + 3,
          );
          if (listeFichiers.indexOf(id) === -1) {
            const fichier = await this.apiMissionService.getFichier(id);
            if (fichier) {
              const b64 = await this.photoService.convertBlobToBase64(fichier);
              id.replace(".pdf", "");
              await Filesystem.writeFile({
                path: id + ".pdf",
                data: b64 as string,
                directory: Directory.Data,
              })
                .then((result) => {
                  console.log("Wrote file", result);
                })
                .catch((error) => console.log(error));
              listeFichiers.push(id);
            }
          }
        }
        if (campagne.ConstatType.genericAppointment.fr_lienods) {
          const id =
            campagne.ConstatType.genericAppointment.fr_lienods.substring(
              campagne.ConstatType.genericAppointment.fr_lienods.indexOf(
                "id=",
              ) + 3,
            );
          if (listeFichiers.indexOf(id) === -1) {
            const fichier = await this.apiMissionService.getFichier(id);
            if (fichier) {
              const b64 = await this.photoService.convertBlobToBase64(fichier);
              id.replace(".pdf", "");
              await Filesystem.writeFile({
                path: id + ".pdf",
                data: b64 as string,
                directory: Directory.Data,
              })
                .then((result) => {
                  console.log("Wrote file", result);
                })
                .catch((error) => console.log(error));
              listeFichiers.push(id);
            }
          }
        }
      }
    } catch (e) {
      this.apiMissionService.log("erreur synchro", JSON.stringify(e));
    }

    Storage.set({
      key: "fr_list_fichiers",
      value: JSON.stringify(listeFichiers),
    });
  }

  async getPicklistValues() {
    let listValues = [];

    await this.apiMissionService
      .getListStatutsTelephoniques()
      .then((data: any[]) => {
        data.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          } else if (a.label > b.label) {
            return 1;
          }
          return 0;
        });
        listValues.push({
          list: "statutstelephoniques",
          data,
        });
        let val = data.find((d) => d.label === "PDV contacté");
        if (val) {
          val.label = "Validé";
        }
        this.listStatutsTelephoniques = data;
      });

    Storage.set({
      key: "fr_picklist_values",
      value: JSON.stringify(listValues),
    });
  }

  async addPhotoToDelete(idphoto) {
    const data = await Storage.get({
      key: "filrouge_delete",
    });
    let tab = [idphoto];
    if (data && data.value) {
      tab = JSON.parse(data.value);
      tab.push(idphoto);
    }
    await Storage.set({
      key: "filrouge_delete",
      value: JSON.stringify(tab),
    });
    return true;
  }

  private traitementMissions(mission, promises, photos) {
    if (
      mission.constat.genericAppointment.photosAvant &&
      mission.constat.genericAppointment.photosAvant.length > 0
    ) {
      mission.constat.genericAppointment.photosAvant.forEach((photo) => {
        if (!photo.isThumbnail && !photo.envoyee) {
          photo.envoyee = true;
          const promise = this.photoService
            .loadSaved(photo)
            .then(async (res) => {
              let blob;
              if (!this.platform.is("hybrid")) {
                blob = this.tools.b64toBlob(photo.webviewPath, "image/jpeg");
              } else {
                blob = await fetch(photo.webviewPath).then((r) => r.blob());
              }
              if (!photos.photosAvant) {
                photos.photosAvant = {
                  files: [{ file: blob, etat: Etat.enAttenteEnvoi }],
                  idMission: mission.activityid,
                  avantapres: "avant",
                };
              } else {
                photos.photosAvant.files.push({
                  file: blob,
                  etat: Etat.enAttenteEnvoi,
                });
              }
              mission.constat.genericAppointment.photosAvant[
                mission.constat.genericAppointment.photosAvant.indexOf(photo)
              ].envoyee = true;
            });
          promises.push(promise);
        }
      });
    }
    if (
      mission.constat.genericAppointment.photosApres &&
      mission.constat.genericAppointment.photosApres.length > 0
    ) {
      mission.constat.genericAppointment.photosApres.forEach((photo) => {
        const promise = this.photoService.loadSaved(photo).then(async (res) => {
          if (!photo.isThumbnail && !photo.envoyee) {
            photo.webviewPath = photo.webviewPath.split(",").pop();
            let blob;
            if (!this.platform.is("hybrid")) {
              blob = this.tools.b64toBlob(photo.webviewPath, "image/jpeg");
            } else {
              blob = await fetch(photo.webviewPath).then((r) => r.blob());
            }
            if (!photos.photosApres) {
              photos.photosApres = {
                files: [{ file: blob, etat: Etat.enAttenteEnvoi }],
                idMission: mission.activityid,
                avantapres: "apres",
              };
            } else {
              photos.photosApres.files.push({
                file: blob,
                etat: Etat.enAttenteEnvoi,
              });
            }
            mission.constat.genericAppointment.photosApres[
              mission.constat.genericAppointment.photosApres.indexOf(photo)
            ].envoyee = true;
          }
        });
        promises.push(promise);
      });
    }
    if (
      mission.constat.appointments &&
      mission.constat.appointments.length > 0
    ) {
      mission.constat.appointments.forEach((appt) => {
        if (appt.photosAvant && appt.photosAvant.length > 0) {
          for (const photo of appt.photosAvant) {
            const promise = this.photoService
              .loadSaved(photo)
              .then(async (res) => {
                if (!photo.isThumbnail && !photo.envoyee) {
                  photo.webviewPath = photo.webviewPath.split(",").pop();
                  let blob;
                  if (!this.platform.is("hybrid")) {
                    blob = this.tools.b64toBlob(
                      photo.webviewPath,
                      "image/jpeg",
                    );
                  } else {
                    blob = await fetch(photo.webviewPath).then((r) => r.blob());
                  }
                  if (!photos.apptAvant) {
                    photos.apptAvant = [
                      {
                        files: [{ file: blob, etat: Etat.enAttenteEnvoi }],
                        idMission: appt.activityid,
                        avantapres: "avant",
                      },
                    ];
                  } else {
                    const found = photos.apptAvant.find(
                      (ph) => ph.idMission == appt.activityid,
                    );
                    if (found) {
                      photos.apptAvant[
                        photos.apptAvant.indexOf(found)
                      ].files.push({
                        file: blob,
                        etat: Etat.enAttenteEnvoi,
                      });
                    } else {
                      photos.apptAvant.push({
                        files: [{ file: blob, etat: Etat.enAttenteEnvoi }],
                        idMission: appt.activityid,
                        avantapres: "avant",
                      });
                    }
                  }
                }
              });
            promises.push(promise);
          }
        }
        if (appt.photosApres && appt.photosApres.length > 0) {
          for (const photo of appt.photosApres) {
            const promise = this.photoService
              .loadSaved(photo)
              .then(async (res) => {
                if (!photo.isThumbnail && !photo.envoyee) {
                  photo.webviewPath = photo.webviewPath.split(",").pop();
                  let blob;
                  if (!this.platform.is("hybrid")) {
                    blob = this.tools.b64toBlob(
                      photo.webviewPath,
                      "image/jpeg",
                    );
                  } else {
                    blob = await fetch(photo.webviewPath).then((r) => r.blob());
                  }
                  if (!photos.apptApres) {
                    photos.apptApres = [
                      {
                        files: [{ file: blob, etat: Etat.enAttenteEnvoi }],
                        idMission: appt.activityid,
                        avantapres: "apres",
                      },
                    ];
                  } else {
                    const found = photos.apptApres.find(
                      (ph) => ph.idMission == appt.activityid,
                    );
                    if (found) {
                      photos.apptApres[
                        photos.apptApres.indexOf(found)
                      ].files.push({
                        file: blob,
                        etat: Etat.enAttenteEnvoi,
                      });
                    } else {
                      photos.apptApres.push({
                        files: [{ file: blob, etat: Etat.enAttenteEnvoi }],
                        idMission: appt.activityid,
                        avantapres: "apres",
                      });
                    }
                  }
                }
              });
            promises.push(promise);
          }
        }
      });
    }
  }
}
