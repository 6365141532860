import {NgModule} from '@angular/core';
import {DatefrPipe} from './datefr/datefr.pipe';
import {OrderbyPipe} from './orderby/orderby.pipe';

@NgModule({
  declarations: [
      DatefrPipe,
    OrderbyPipe
  ],
  imports: [],
  exports: [
      DatefrPipe,
    OrderbyPipe
  ]
})
export class PipesModule {
}
