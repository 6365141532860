<ion-content scroll-y="false">
    <ion-grid>
        <ion-row>
            <ion-col size="12" class="text-center modal-synchro">
                <ion-spinner class="spinner"></ion-spinner>
                <ion-title>Synchronisation</ion-title>
                <p>La synchronisation de vos données est en cours.</p>
                <p>{{message}}</p>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer>
    <ion-grid>
        <ion-row class="bas">
            <ion-col size="12" class="text-center">
                <ion-button expand="full" color="dark" (click)="close()" [disabled]="!canHide">Masquer</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
