import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MissionsFiltresService {
    missions: any[] = [];
    selectedClients: string[] = [];
    selectedCampagnes: string[] = [];
    selectedPdvs: string[] = [];
    selectedUgas: string[] = [];
    selectedVilles: string[] = [];
    selectedStatuts: number[] = [];
    fildeleau: boolean | null = null;
    datelimite: boolean | null = null;
    dateplanif: boolean | null = null;
    priserdv: boolean | null = null;
    dispositifs: string[] = [];
    priorite: number[] = [];
    typeMission;
    mois;
    annee;

    constructor() {
        const today = new Date();
        this.mois = today.getMonth();
        this.annee = today.getFullYear();
    }

    hasFilter() {
        return this.selectedCampagnes?.length ||
            this.selectedClients?.length ||
            this.selectedPdvs?.length ||
            this.selectedUgas?.length ||
            this.selectedVilles?.length ||
            this.fildeleau !== null ||
            this.datelimite !== null ||
            this.dateplanif !== null ||
            this.priserdv !== null ||
            this.dispositifs?.length ||
            this.priorite?.length ||
            this.selectedStatuts?.length;
    }

    moisSuiv() {
        if (this.mois < 11) {
            this.mois++;
        } else {
            this.mois = 0;
            this.annee++;
        }
    }

    moisPrec() {
        if (this.mois > 0) {
            this.mois--;
        } else {
            this.mois = 11;
            this.annee--;
        }
    }

    clear() {
        this.selectedClients = [];
        this.selectedPdvs = [];
        this.selectedCampagnes = [];
        this.selectedVilles = [];
        this.selectedUgas = [];
        this.dispositifs = [];
        this.priorite = [];
        this.selectedStatuts = [];
        this.fildeleau = null;
        this.datelimite = null;
        this.dateplanif = null;
        this.priserdv = null;
    }
}
