import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '../pipes/pipes.module';
import {ConstatModalComponent} from './constat-modal/constat-modal.component';
import {ModalCommentaireComponent} from './modal-commentaire/modal-commentaire.component';
import {ModalPdvComponent} from './modal-pdv/modal-pdv.component';
import {SignaturepadModalComponent} from './signaturepad-modal/signaturepad-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule
    ],
    declarations: [
        ModalPdvComponent,
        ConstatModalComponent,
        ModalCommentaireComponent,
        SignaturepadModalComponent
    ],
})

export class ComponentsModule {
}
